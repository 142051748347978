import React, { useState } from 'react';
import SidebarNew from '../SidebarNew/SidebarNew'
import { useForm } from "react-hook-form";
import axios from 'axios';

const CreateNotice = () => {
    const [imageURL, setImageURL] = useState(null);
    const [user, setUser] = useState(null);
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();

    const handleImageUpload = (event) => {
        const imageData = new FormData();
        imageData.set('key', '2dad6d7bf6cef1ec0ba10a9e39066744');
        imageData.append('image', event.target.files[0]);

        axios.post('https://api.imgbb.com/1/upload', imageData)
            .then(res => {
                console.log(res.data.data.display_url);
                setImageURL(res.data.data.display_url);
            })
            .catch(err => {
                console.log(err);
            })
        console.log('image clicked')
    }

    // format date
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
        return formattedDate;
    };

    const onSubmit = data => {

        const formattedDate = formatDate(data.date)

        const newNoticeData = {
            date: formattedDate,
            // headline: data.headline,
            // description: data.description,
            imageURL: imageURL
        }
        console.log(data)
        fetch(`https://al-maghrib-server.vercel.app/notice/createNotice`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newNoticeData)
        })
            .then(data => {
                // console.log("ddd: ",data)
                if (data.ok) {
                    alert("Notice created successfully.")
                } else { alert("Sorry. Notice cannot created.") }

            })

        reset()

    }


    return (
        <div style={{ display: 'flex' }}>
            <SidebarNew />
            <div className='container'>
                <h1 className='text-violet-900	text-6xl	mb-5'>Create A Notice</h1>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <label class="block">
                        <span class="block text-sm font-medium text-slate-700">Date</span>
                        <input type="date" name="date" {...register("date", { required: true })} className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        {errors.date && <span>This field is required</span>}
                    </label>


                    <label class="block">
                        <span class="mt-3 block text-sm font-medium text-slate-700">Notice Image</span>
                        <div class="shrink-0">
                            <img class="h-auto w-auto object-contain" src={imageURL} alt="" />
                        </div>
                        <input name="file" type="file" onChange={handleImageUpload} class="block w-full text-sm text-slate-500
                            file:mr-4 file:py-2 file:px-4
                            file:rounded-full file:border-0
                            file:text-sm file:font-semibold
                            file:bg-violet-50 file:text-violet-700
                            hover:file:bg-violet-100
                        "/>
                    </label>

                    {/* <label class="block">
                        <span class="mt-3 block text-sm font-medium text-slate-700">Headline</span>
                        <textarea type="text" name="headline" {...register("headline", { required: true })} rows="4" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        {errors.headline && <span>This field is required</span>}
                    </label>

                    <label class="block">
                        <span class="mt-3 block text-sm font-medium text-slate-700">Description Text: </span>
                        <textarea type="text" name="description" {...register("description", { required: true })} rows="4" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        {errors.description && <span>This field is required</span>}
                    </label> */}

                    <input type="submit" className='mt-3 btn btn-success text-dark' />
                </form>
            </div>

        </div>
    );
};

export default CreateNotice;