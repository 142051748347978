import React, { useEffect, useState } from 'react';
import SidebarNew from '../SidebarNew/SidebarNew'

const EmployeeApplication = () => {
    const [allApplications, setAllApplications] = useState([]);

    useEffect(() => {
        fetch(`https://al-maghrib-server.vercel.app/career/all`)
            .then(res => res.json())
            .then(data => {
                setAllApplications(data.allEmployeeApplications)
            })
    }, []);

    const handle_date_time = (originalDateTime) => {
        const originalDateObject = new Date(originalDateTime);

        // Add 6 hours to the time
        originalDateObject.setUTCHours(originalDateObject.getUTCHours() + 0.5);

        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = originalDateObject.toLocaleDateString('en-US', options);
        const formattedTime = originalDateObject.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

        return { formattedDate, formattedTime };
    }

    const handleDelete = (applicationId) => {
        fetch(`https://al-maghrib-server.vercel.app/career/delete/${applicationId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(data => {
                if (data.ok) alert('Application has been removed. Please refresh the page')
                else alert('XX:  Error to delete. Refresh the page and try again')
            })
    }
    return (
        <div style={{ display: 'flex' }}>
            <SidebarNew />
            <div className='container'>
                <h1 className='text-violet-900	text-6xl	mb-5'>Online Application: <sub>Employee</sub>  <sup className='text-danger'>{allApplications.length} </sup></h1>

                <table className="border-collapse border border-slate-400">
                    <thead>
                        <tr className='hover:bg-slate-400'>
                            <th className="border border-slate-300 p-3">Sl No</th>
                            <th className="border border-slate-300 p-3">Apply Date</th>
                            <th className="border border-slate-300 p-3">Name </th>
                            <th className="border border-slate-300 p-3">Seeking Position </th>
                            <th className="border border-slate-300 p-3">Resume Link </th>
                            <th className="border border-slate-300 p-3">Contact Info </th>
                            <th className="border border-slate-300 p-3">Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allApplications.length !== 0 ? allApplications.map((application, i) =>
                                <tr key={i} className='hover:bg-slate-400'>
                                    <td className="border border-slate-300 p-3">{i + 1}</td>
                                    <td className="border border-slate-300 p-3">
                                        {handle_date_time(application.createdAt).formattedDate}
                                        <br />
                                        {handle_date_time(application.createdAt).formattedTime}
                                    </td>
                                    <td className="border border-slate-300 p-3">{application.name}</td>
                                    <td className="border border-slate-300 p-3">{application.career_position}</td>
                                    <td className="border border-slate-300 p-3"><a href={application.resume_url} target='_blank'>resume</a></td>
                                    <td className="border border-slate-300 p-3">
                                        {application.contact} <br />
                                        {application.email}
                                    </td>
                                    <td className="border border-slate-300 p-3">
                                        <button onClick={(e) => handleDelete(application._id)} className='btn btn-danger outline outline-1'>X</button>
                                    </td>
                                </tr>)
                                :
                                <tr>Data loading...</tr>
                        }


                    </tbody>
                </table>
            </div>



        </div>
    );
};

export default EmployeeApplication;