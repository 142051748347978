import React, { useEffect, useState } from 'react';
import SidebarNew from '../SidebarNew/SidebarNew'
import image from '../../Image/al-transparent.png'


const AllNotice = () => {
    const [allNotice, setAllNotice] = useState([]);

    useEffect(() => {
        fetch(`https://al-maghrib-server.vercel.app/notice/all`)
            .then(res => res.json())
            .then(data => {
                // console.log("all notice data: ", data)
                setAllNotice(data.notice)

            })
    }, []);

    const handleDelete = (noticeId) => {
        fetch(`https://al-maghrib-server.vercel.app/notice/delete/${noticeId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(data => {
                if (data.ok) alert('Notice has been deleted. Please refresh the page')
                else alert('XX:  Error to delete. Refresh the page and try again')
            })
    }
    return (
        <div style={{ display: 'flex' }}>
            <SidebarNew />
            <div className='container'>
                <h1 className='text-violet-900 text-6xl mb-5'>All Notice</h1>
                <table className="border-collapse border border-slate-400">
                    <thead>
                        <tr className='hover:bg-slate-400'>
                            <th className="border border-slate-300 p-3">Sl No</th>
                            <th className="border border-slate-300 p-3">Date</th>
                            <th className="border border-slate-300 p-3">Image (Click to enlarge the image) </th>
                            <th className="border border-slate-300 p-3">Delete </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allNotice.length !== 0 ? allNotice.map((notice, i) =>
                                <tr key={i} className='hover:bg-slate-400'>
                                    <td className="border border-slate-300 p-3">{i + 1}</td>
                                    <td className="border border-slate-300 p-3">{notice.date}</td>
                                    <td className="border border-slate-300 p-3">
                                        <a href={notice.imageURL || image} target='_blank'><img className='object-contain h-24' src={notice.imageURL || image} alt="" /></a> 
                                    </td>
                                    <td className="border border-slate-300 p-3">
                                        <button onClick={(e) => handleDelete(notice._id)} className='btn btn-danger outline outline-1'>Delete</button>
                                    </td>
                                </tr>
                            ) :
                                <tr>Data loading...</tr>
                        }


                    </tbody>
                </table>



            </div>
        </div>
    );
};

export default AllNotice;