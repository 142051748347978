import React, { useState } from 'react';
import SidebarNew from '../SidebarNew/SidebarNew'
import { useForm } from "react-hook-form";
import axios from 'axios';

const CreateMember = () => {
    const [imageURL, setImageURL] = useState(null);
    const [user, setUser] = useState(null);
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();

    const handleImageUpload = (event) => {
        const imageData = new FormData();
        imageData.set('key', '2dad6d7bf6cef1ec0ba10a9e39066744');
        imageData.append('image', event.target.files[0]);

        axios.post('https://api.imgbb.com/1/upload', imageData)
            .then(res => {
                setImageURL(res.data.data.display_url);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const onSubmit = data => {
        const newMemberData = {
            ...data,
            imageURL: imageURL
        }
        
        fetch(`https://al-maghrib-server.vercel.app/team/addPersonToCategory`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newMemberData)
        })
            .then(data => {
                if (data.ok) {
                    alert("Member created successfully.")
                } else { alert("Sorry. Member cannot created.") }

            })

        reset()



    }


    return (
        <div style={{ display: 'flex' }}>
            <SidebarNew />
            <div className='container'>
                <h1 className='text-violet-900	text-6xl	mb-5'>Create A Member/Teacher</h1>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <label class="block">
                        <span class="block text-sm font-medium text-slate-700">Date</span>
                        <input type="date" name="date" {...register("date", { required: true })} className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        {errors.date && <span>This field is required</span>}
                    </label>

                    <label class="block">
                        <span class="mt-3 block text-sm font-medium text-slate-700">Select Category: </span>
                        <select name="category"  {...register("category", { required: true })} className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" >
                            <option selected disabled value="">Select Class/Level</option>
                            <option>Advisors</option>
                            <option>Principal and Vice-Principal</option>
                            <option>Administrative</option>
                            <option>Teacher</option>
                            <option>Hifz Dept</option>
                            <option>ICT Panel</option>
                            <option>Advocate Panel</option>
                            <option>Medical Panel</option>
                        </select>
                        {errors.category && <span>This field is required</span>}
                    </label>

                    <label class="block">
                        <span class="mt-3 block text-sm font-medium text-slate-700">Name  (required):</span>
                        <input type="text" name="name" {...register("name", { required: true })} className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        {errors.name && <span>This field is required</span>}
                    </label>


                    <label class="block">
                        <span class="mt-3 block text-sm font-medium text-slate-700">Member Image</span>
                        <div class="shrink-0">
                            <img class="h-40 w-40 object-contain" src={imageURL} alt="" />
                        </div>
                        <input name="file" type="file" onChange={handleImageUpload} class="block w-full text-sm text-slate-500
                            file:mr-4 file:py-2 file:px-4
                            file:rounded-full file:border-0
                            file:text-sm file:font-semibold
                            file:bg-violet-50 file:text-violet-700
                            hover:file:bg-violet-100
                        "/>
                    </label>

                    <label class="block">
                        <span class="mt-3 block text-sm font-medium text-slate-700">Role of the member  (required)</span>
                        <input type="text" name="role" {...register("role", { required: true })} className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        {/* errors will return when field validation fails  */}
                        {errors.role && <span>This field is required</span>}
                    </label>

                    <label class="block">
                        <span class="mt-3 block text-sm font-medium text-slate-700">Experience Text (optional): </span>
                        <textarea type="text" name="experience1" {...register("experience1")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        <textarea type="text" name="experience2" {...register("experience2")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        <textarea type="text" name="experience3" {...register("experience3")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                    </label>

                    <label class="block">
                        <span class="mt-3 block text-sm font-medium text-slate-700">Qualification/Graduation Text (optional): </span>
                        <textarea type="text" name="qualification1" {...register("qualification1")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        <textarea type="text" name="qualification2" {...register("qualification2")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        <textarea type="text" name="qualification3" {...register("qualification3")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                    </label>

                    <label class="block">
                        <span class="mt-3 block text-sm font-medium text-slate-700">Training/Research Text (optional): </span>
                        <textarea type="text" name="training_research1" {...register("training_research1")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        <textarea type="text" name="training_research2" {...register("training_research2")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        <textarea type="text" name="training_research3" {...register("training_research3")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                    </label>                 

                    <label class="block">
                        <span class="mt-3 block text-sm font-medium text-slate-700">Publication (optional): </span>
                        <textarea type="text" name="publication1" {...register("publication1")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        <textarea type="text" name="publication2" {...register("publication2")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        <textarea type="text" name="publication3" {...register("publication3")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                    </label>

                    <label class="block">
                        <span class="mt-3 block text-sm font-medium text-slate-700">E-mail (required): </span>
                        <input type="email" name="member_email" {...register("member_email", { required: true })} className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        {errors.member_email && <span>This field is required</span>}
                    </label>

                    <input type="submit" className='mt-3 btn btn-success text-dark' />
                </form>
            </div>

        </div>
    );
};

export default CreateMember;