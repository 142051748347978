import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../App';
import SidebarNew from '../SidebarNew/SidebarNew'
import { useForm } from "react-hook-form";


const Profile = () => {
    const [loggedInUser, setLoggedInUser] = useContext(UserContext);
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();


    const onSubmit = data => {
        if(data.newPassword !== data.confirmPassword) {  
            alert("Passwords do not matched !!!"); 
            reset();
            return ;
        }
        
        const profileData = {
            name: loggedInUser.name,
            email: loggedInUser.email,
            newPassword: data.newPassword,
            prevPassword: data.prevPassword
        }
        console.log("ddd: ", profileData)


        fetch(`https://al-maghrib-server.vercel.app/auth/updatePassword/${loggedInUser._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(profileData)
        })
            .then(data => {
                console.log("ddd: ", data)
                if (data.ok) {
                    alert("Password updated successfully.")
                } else { alert("Sorry. password cannot updated. Try again later or inform developer") }

            })

        reset()

    }
    return (
        <div style={{ display: 'flex' }}>
            <SidebarNew />
            <div className='container'>
                <h1 className='text-violet-900 text-6xl mb-5'>Profile of {loggedInUser.name}</h1>

                <label class="block">
                    <span class="mt-3 block text-sm font-medium text-slate-700">Username</span>
                    <p class="block w-full text-sm text-slate-500 ">{loggedInUser.name}</p>
                </label>

                <label class="block">
                    <span class="mt-3 block text-sm font-medium text-slate-700">Email</span>
                    <p class="block w-full text-sm text-slate-500 ">{loggedInUser.email}</p>
                </label>
                {loggedInUser.email &&
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h1 className='text-violet-900 text-2xl mt-5'>Change Password</h1>
                        <label class="block">
                            <span class="mt-3 block text-sm font-medium text-slate-700">New Password</span>
                            <input name="newPassword" type="password"  {...register("newPassword",)} class="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                            {errors.newPassword && <span>This field is required</span>}
                        </label>
                        <label class="block">
                            <span class="mt-3 block text-sm font-medium text-slate-700">Confirm Password</span>
                            <input name="confirmPassword" type="password"  {...register("confirmPassword",)} class="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                            {errors.confirmPassword && <span>This field is required</span>}
                        </label>
                        <label class="block">
                            <span class="mt-3 block text-sm font-medium text-slate-700">Previous Password</span>
                            <input name="prevPassword" type="password"  {...register("prevPassword",)} class="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                            {errors.prevPassword && <span>This field is required</span>}
                        </label>

                        <input type="submit" className='mt-3 btn btn-success text-dark mt-5 mb-5' />
                    </form>
                }
            </div>
        </div>
    );
};

export default Profile;