import React, { useEffect, useState } from 'react';
import SidebarNew from '../SidebarNew/SidebarNew'

const AllUpcomingEvents = () => {
    const [allUpcomingEvents, setAllUpcomingEvents] = useState([]);
    useEffect(() => {
        fetch(`https://al-maghrib-server.vercel.app/upcomingEvent/all`)
            .then(res => res.json())
            .then(data => {
                setAllUpcomingEvents(data.upcomingEvents)

            })
    }, []);

    const handleDelete = (upcomingEventId) => {
        fetch(`https://al-maghrib-server.vercel.app/upcomingEvent/delete/${upcomingEventId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(data => {
                if (data.ok) alert('One upcoming event has been deleted. Please refresh the page')
                else alert('XX:  Error to delete. Refresh the page and try again')
            })
    }
    return (
        <div style={{ display: 'flex' }}>
            <SidebarNew />
            <div className='container'>
                <h1 className='text-violet-900	text-6xl mb-5'>All Upcoming Events</h1>

                <table className="border-collapse border border-slate-400">
                    <thead>
                        <tr className='hover:bg-slate-400'>
                            <th className="border border-slate-300 p-3">Sl No</th>
                            <th className="border border-slate-300 p-3">Date of this event</th>
                            <th className="border border-slate-300 p-3">Headline </th>
                            <th className="border border-slate-300 p-3">Delete </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allUpcomingEvents.length !== 0 ? allUpcomingEvents.map((upcomingEvent, i) =>
                                <tr key={i} className='hover:bg-slate-400'>
                                    <td className="border border-slate-300 p-3">{i + 1}</td>
                                    <td className="border border-slate-300 p-3">{upcomingEvent.date}</td>
                                    <td className="border border-slate-300 p-3">{upcomingEvent.headline}</td>
                                    <td className="border border-slate-300 p-3">
                                        <button onClick={(e) => handleDelete(upcomingEvent._id)} className='btn btn-danger outline outline-1'>Delete</button>
                                    </td>
                                </tr>
                            ) :
                                <tr>Data loading...</tr>
                        }


                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AllUpcomingEvents;