import React, { createContext, useState } from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from './components/Home/Home';
import { ProSidebarProvider } from 'react-pro-sidebar';
import FrontPage from './components/FrontPage/FrontPage';
import CreateNotice from './components/Notice/CreateNotice'
import CreateNews from './components/News/CreateNews'
import Login from './components/Auth/Login'
import Register from './components/Auth/Register'
import NotFound from './components/NotFound/index.jsx'
import AllNews from './components/News/AllNews';
import AllNotice from './components/Notice/AllNotice';
import Profile from './components/MyProfile/Profile';
import StudentApplication from './components/OnlineApplication/StudentApplication';
import CreateMember from './components/Team/CreateMember';
import AllMembers from './components/Team/AllMembers'
import AllMagazines from './components/Magazine/AllMagazines';
import CreateMagazine from './components/Magazine/CreateMagazine';
import NewApplication from './components/OnlineApplication/NewApplication';
import AllUpcomingEvents from './components/Magazine/AllUpcomingEvents';
import CreateUpcomingEvent from './components/Magazine/CreateUpcomingEvent';
import EmployeeApplication from './components/OnlineApplication/EmployeeApplication';

export const UserContext = createContext();

function App() {
  const [loggedInUser, setLoggedInUser] = useState({});
  return (
    <div>
      <UserContext.Provider value={[loggedInUser, setLoggedInUser]}>
        <Router>
          <Routes>
            <Route exact path="/" element={<ProSidebarProvider> <Home /></ProSidebarProvider>} />
            <Route path="/frontPage" element={<ProSidebarProvider><FrontPage /></ProSidebarProvider>} />
            <Route path="/allNotice" element={<ProSidebarProvider><AllNotice /></ProSidebarProvider>} />
            <Route path="/createNotice" element={<ProSidebarProvider><CreateNotice /></ProSidebarProvider>} />
            <Route path="/allNews" element={<ProSidebarProvider><AllNews /></ProSidebarProvider>} />
            <Route path="/createNews" element={<ProSidebarProvider><CreateNews /></ProSidebarProvider>} />
            <Route path="/myProfile" element={<ProSidebarProvider><Profile /></ProSidebarProvider>} />
            {/* <Route path="/newStudentApplication/:id" element={<ProSidebarProvider><NewApplication/></ProSidebarProvider>}/>
          <Route path="/newStudentApplication" element={<ProSidebarProvider><StudentApplication/></ProSidebarProvider>}/> */}
            <Route path="/newEmployeeApplication" element={<ProSidebarProvider><EmployeeApplication /></ProSidebarProvider>} />
            <Route path="/createMember" element={<ProSidebarProvider><CreateMember /></ProSidebarProvider>} />
            <Route path="/allMembers" element={<ProSidebarProvider><AllMembers /></ProSidebarProvider>} />
            <Route path="/allMagazines" element={<ProSidebarProvider><AllMagazines /></ProSidebarProvider>} />
            <Route path="/createMagazine" element={<ProSidebarProvider><CreateMagazine /></ProSidebarProvider>} />
            <Route path="/allUpcomingEvents" element={<ProSidebarProvider><AllUpcomingEvents /></ProSidebarProvider>} />
            <Route path="/createUpcomingEvent" element={<ProSidebarProvider><CreateUpcomingEvent /></ProSidebarProvider>} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </UserContext.Provider>
    </div>
  );
}

export default App;
