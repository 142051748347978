import React, { useContext } from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { UserContext } from '../../App';

const Home = () => {
    const [loggedInUser, setLoggedInUser] = useContext(UserContext);
    return (
        <div style={{ display: "flex" }}>
            {/* <ProSidebarProvider> */}
            {/* <Header /> */}
            <div>
                <Sidebar>
                    <Menu>
                        <MenuItem> <Link to={"/"}>Home</Link> </MenuItem>
                        {/* <SubMenu label="Front Page">
                            <MenuItem> <Link to={"/frontPage"}>Carousel Pic</Link></MenuItem>
                            <MenuItem> Who We Are</MenuItem>
                            <MenuItem> Principal</MenuItem>
                        </SubMenu> */}
                        <MenuItem> <Link to={"/frontPage"}>Front Page</Link></MenuItem>

                        <SubMenu label="About">
                            <MenuItem> About School </MenuItem>
                            <MenuItem> About Advisors </MenuItem>
                            <SubMenu label="Team">
                                <MenuItem><Link to={"/allMembers"}>All Members</Link> </MenuItem>
                                <MenuItem><Link to={"/createMember"}>Create a Member</Link> </MenuItem>
                            </SubMenu>
                        </SubMenu>

                        <SubMenu label="Notice">
                            <MenuItem><Link to={"/allNotice"}>All Notice</Link></MenuItem>
                            <MenuItem> <Link to={"/createNotice"}>Create A Notice</Link> </MenuItem>
                        </SubMenu>

                        <SubMenu label="News">
                            <MenuItem><Link to={"/allNews"}>All News</Link></MenuItem>
                            <MenuItem> <Link to={"/createNews"}>Create A News</Link> </MenuItem>
                        </SubMenu>

                        <SubMenu label="Magazine">
                            <MenuItem><Link to={"/allUpcomingEvents"}>All Upcoming Events</Link></MenuItem>
                            <MenuItem> <Link to={"/createUpcomingEvent"}>Create An Upcoming Event</Link> </MenuItem>
                            <MenuItem><Link to={"/allMagazines"}>All Magazines</Link></MenuItem>
                            <MenuItem> <Link to={"/createMagazine"}>Create A Magazine</Link> </MenuItem>
                        </SubMenu>

                        <SubMenu label="Online Applications">
                            {/* <MenuItem><Link to={"/newStudentApplication"}>Pending Student </Link> </MenuItem> */}
                            <MenuItem><Link to={"/newEmployeeApplication"}> Employee Career </Link></MenuItem>
                        </SubMenu>
                        {
                            loggedInUser.email === 'admin1@ams.com' &&
                            <SubMenu label="Admin Part">
                                <MenuItem> <Link to={"/register"}>Register Admin</Link> </MenuItem>
                            </SubMenu>
                        }
                        <MenuItem> <Link to={"/myProfile"}>My Profile</Link> </MenuItem>
                        <MenuItem> Logout </MenuItem>
                    </Menu>
                </Sidebar>;
            </div>

            {/* </ProSidebarProvider> */}

        </div>
    );
};

export default Home;