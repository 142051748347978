import React, { useEffect, useState } from 'react';
import SidebarNew from '../SidebarNew/SidebarNew'
import image from '../../Image/al-transparent.png'


const AllNews = () => {
    const [allnews, setAllNews] = useState([]);

    useEffect(() => {
        fetch(`https://al-maghrib-server.vercel.app/news/all`)
            .then(res => res.json())
            .then(data => {
                setAllNews(data.news)

            })
    }, []);

    const handleDelete = (newsId) => {
        fetch(`https://al-maghrib-server.vercel.app/news/delete/${newsId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(data => {
                if(data.ok) alert('News has been deleted. Please refresh the page') 
                else alert('XX:  Error to delete. Refresh the page and try again') 
            })
    }
    return (
        <div style={{ display: 'flex' }}>
            <SidebarNew />
            <div className='container'>
                <h1 className='text-violet-900 text-6xl mb-5'>All News</h1>
                {
                    allnews.map(news => <div className='flex border p-2 '>
                        <div className='w-80 border'><img className='object-contain w-48 h-48 ' src={news.imageURL || image} alt="" /></div>
                        <div>
                            <p>Date: {news.date}</p>
                            <p>{news.headline}</p>
                            <p>{news.description}</p>
                            <button className='btn btn-success outline outline-1'>Update</button>
                            <button onClick={(e) => handleDelete(news._id)} className='btn btn-danger outline outline-1'>Delete</button>
                        </div>
                    </div>
                    )

                }


            </div>
        </div>
    );
};

export default AllNews;