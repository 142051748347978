import React from 'react';
import SidebarNew from '../SidebarNew/SidebarNew'
import { useForm } from 'react-hook-form';

const CreateUpcomingEvent = () => {
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();

    // format date
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
        return formattedDate;
    };

    const onSubmit = data => {
        const formattedDate = formatDate(data.date)
        data.date = formattedDate;
        fetch(`https://al-maghrib-server.vercel.app/upcomingEvent/createUpcomingEvent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(data => {
                if (data.ok) {
                    alert("Upcoming Event created successfully.")
                } else { alert("Sorry. Upcoming Event cannot created.") }

            })

        reset()

    }
    return (
        <div style={{ display: 'flex' }}>
            <SidebarNew />
            <div className='container'>
                <h1 className='text-violet-900	text-6xl mb-5'>Create An Upcoming Event</h1>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <label className="block">
                        <span className="block text-sm font-medium text-slate-700">Date of upcoming event</span>
                        <input type="date" name="date" {...register("date", { required: true })} className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        {errors.date && <span>This field is required</span>}
                    </label>

                    <label class="block">
                        <span className="mt-3 block text-sm font-medium text-slate-700">Headline</span>
                        <textarea type="text" name="headline" {...register("headline", { required: true })} rows="4" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        {/* errors will return when field validation fails  */}
                        {errors.headline && <span>This field is required</span>}
                    </label>

                    <br /><br />
                    <input type="submit" className='mt-3 mb-3 btn btn-success text-dark' />
                </form>
            </div>
        </div>
    );
};

export default CreateUpcomingEvent;