import React, { useEffect, useState } from 'react';
import SidebarNew from '../SidebarNew/SidebarNew'
import image from '../../Image/al-transparent.png'
import { useForm } from "react-hook-form";
import axios from 'axios';
import './AllMembers.css'

const AllMembers = () => {
    // const DATA = [
    //     {
    //         category: "Advisors",
    //         items: [
    //             { category: "Advisors", name: "Habibur Rahman", role: "advisor 1" },
    //             { category: "Advisors", name: "Najmul", role: "advisor 2" },
    //         ],
    //         tint: 1,
    //     },
    //     {
    //         category: "Principal and Vice-Principal",
    //         items: [
    //             { category: "Principal and Vice-Principal", name: "Mohib", role: "principla" },
    //             { category: "Principal and Vice-Principal", name: "Emdad", role: "VP" },
    //             { category: "Principal and Vice-Principal", name: "Minhaj", role: "director" },
    //             { category: "Principal and Vice-Principal", name: "tanim", role: "asst. director" },
    //         ],
    //         tint: 2,
    //     },
    //     {
    //         category: "Administrative",
    //         items: [
    //             { category: "Administrative", name: "Osi", role: "hr" },
    //             { category: "Administrative", name: "Mobile", role: "academic coordinatior" },
    //         ],
    //         tint: 3,
    //     },
    //     {
    //         category: "Teacher",
    //         items: [
    //             { category: "Teacher", name: "Prof. Azim", role: "teacher 1" },
    //             { category: "Teacher", name: "Ashraf Kazi", role: "assistant teacher 1" },
    //         ],
    //         tint: 4,
    //     },
    //     {
    //         category: "Hifz Dept",
    //         items: [
    //             { category: "Hifz Dept", name: "yenado", role: "Hafez 1" },
    //             { category: "Hifz Dept", name: "asssss", role: "hafez 2" },
    //         ],
    //         tint: 5,
    //     },
    //     {
    //         category: "ICT Panel",
    //         items: [
    //             { category: "ICT Panel", name: "Abu Bakar", role: "senior 1ct" },
    //             { category: "ICT Panel", name: "Belal", role: "junior 1" },
    //         ],
    //         tint: 6,
    //     },
    //     {
    //         category: "Advocate Panel",
    //         items: [
    //             { category: "Advocate Panel", name: "ioha", role: "advocate 1" },
    //             { category: "Advocate Panel", name: "microphone", role: "advocate 2" },
    //         ],
    //         tint: 7,
    //     },
    //     {
    //         category: "Medical Panel",
    //         items: [
    //             { category: "Medical Panel", name: "dr. shamim", role: "doctor 1" },
    //             { category: "Medical Panel", name: "dr. mohibullah", role: "doctor 2" },
    //         ],
    //         tint: 8,
    //     },
    // ];

    const [allMembers, setAllMembers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalMember, setModalMember] = useState({});
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [imageURL, setImageURL] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch(`https://al-maghrib-server.vercel.app/team/all-members`)
            .then(res => res.json())
            .then(data => {
                setAllMembers(data.members)
                setData(data.members)
            })
    }, []);

    const handleImageUpload = (event) => {
        const imageData = new FormData();
        imageData.set('key', '2dad6d7bf6cef1ec0ba10a9e39066744');
        imageData.append('image', event.target.files[0]);

        axios.post('https://api.imgbb.com/1/upload', imageData)
            .then(res => {
                setImageURL(res.data.data.display_url);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleDelete = (memberId) => {
        fetch(`https://al-maghrib-server.vercel.app/team/delete-one/${memberId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(data => {
                if (data.ok) alert('Member has been deleted. Please refresh the page')
                else alert('XX:  Error to delete. Refresh the page and try again')
            })
    }

    const handleEdit = (member) => {
        setShowModal(true);
        setModalMember(member)
    }

    const onSubmit = data => {
        const modifiedMemberData = {
            ...data,
            imageURL: imageURL
        }
        fetch(`https://al-maghrib-server.vercel.app/team/updateMember/${modalMember._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(modifiedMemberData)
        })
            .then(data => {
                if (data.ok) {
                    alert("Member Updated Successfully.");
                    setShowModal(false);
                } else { alert("Sorry. Member cannot updated.") }

            })


    }

    const EditModal = () => {
        return (
            <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl modal-dialog-scrollable ">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-3xl font-semibold">
                                    Edit of {modalMember.name}
                                </h3>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="relative p-6 flex-auto">
                                    <label class="block">
                                        <span class="block text-sm font-medium text-slate-700">Date</span>
                                        <input type="date" name="date" {...register("date", { required: true })} className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                        {errors.date && <span>This field is required</span>}
                                    </label>

                                    <label class="block">
                                        <span class="mt-3 block text-sm font-medium text-slate-700">Select Category: </span>
                                        <select name="category"  {...register("category", { required: true })} className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" >
                                            <option selected disabled value="">Select Class/Level</option>
                                            <option>Advisors</option>
                                            <option>Administrative</option>
                                            <option>Teacher</option>
                                            <option>Hifz Dept</option>
                                            <option>ICT Panel</option>
                                            <option>Advocate Panel</option>
                                            <option>Medical Panel</option>
                                        </select>
                                        {errors.category && <span>This field is required</span>}
                                    </label>

                                    <label class="block">
                                        <span class="mt-3 block text-sm font-medium text-slate-700">Name  (required):</span>
                                        <input type="text" name="name" {...register("name", { required: true })} className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                        {errors.name && <span>This field is required</span>}
                                    </label>


                                    <label class="block">
                                        <span class="mt-3 block text-sm font-medium text-slate-700">Member Image</span>
                                        <div class="shrink-0">
                                            <img class="h-40 w-40 object-contain" src={imageURL} alt="" />
                                        </div>
                                        <input name="file" type="file" onChange={handleImageUpload} class="block w-full text-sm text-slate-500
                            file:mr-4 file:py-2 file:px-4
                            file:rounded-full file:border-0
                            file:text-sm file:font-semibold
                            file:bg-violet-50 file:text-violet-700
                            hover:file:bg-violet-100
                        "/>
                                    </label>

                                    <label class="block">
                                        <span class="mt-3 block text-sm font-medium text-slate-700">Role of the member  (required)</span>
                                        <input type="text" name="role" {...register("role", { required: true })} className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                        {/* errors will return when field validation fails  */}
                                        {errors.role && <span>This field is required</span>}
                                    </label>

                                    <label class="block">
                                        <span class="mt-3 block text-sm font-medium text-slate-700">Profession Text (optional): </span>
                                        <textarea type="text" name="profession1" {...register("profession1")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                        <textarea type="text" name="profession2" {...register("profession2")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                        <textarea type="text" name="profession3" {...register("profession3")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                    </label>

                                    <label class="block">
                                        <span class="mt-3 block text-sm font-medium text-slate-700">Qualification/Graduation Text (optional): </span>
                                        <textarea type="text" name="qualification1" {...register("qualification1")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                        <textarea type="text" name="qualification2" {...register("qualification2")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                        <textarea type="text" name="qualification3" {...register("qualification3")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                    </label>

                                    <label class="block">
                                        <span class="mt-3 block text-sm font-medium text-slate-700">Experience Text (optional): </span>
                                        <textarea type="text" name="experience1" {...register("experience1")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                        <textarea type="text" name="experience2" {...register("experience2")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                        <textarea type="text" name="experience3" {...register("experience3")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                    </label>

                                    <label class="block">
                                        <span class="mt-3 block text-sm font-medium text-slate-700">Training/Research Text (optional): </span>
                                        <textarea type="text" name="training_research1" {...register("training_research1")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                        <textarea type="text" name="training_research2" {...register("training_research2")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                        <textarea type="text" name="training_research3" {...register("training_research3")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                    </label>

                                    <label class="block">
                                        <span class="mt-3 block text-sm font-medium text-slate-700">Publication (optional): </span>
                                        <textarea type="text" name="publication1" {...register("publication1")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                        <textarea type="text" name="publication2" {...register("publication2")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                        <textarea type="text" name="publication3" {...register("publication3")} rows="2" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                    </label>

                                    <label class="block">
                                        <span class="mt-3 block text-sm font-medium text-slate-700">E-mail (required): </span>
                                        <input type="email" name="member_email" {...register("member_email", { required: true })} className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                                        {errors.member_email && <span>This field is required</span>}
                                    </label>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="submit"

                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>


        );
    };

    // drag and drop
    const [draggedItem, setDraggedItem] = useState(null);
    const [dataChanged, setDataChanged] = useState(false);

    const handleDragStart = (category, itemIndex) => {
        setDraggedItem({ category, itemIndex });
    };

    const handleDragOver = (e, targetCategory, targetItemIndex) => {
        e.preventDefault();

        if (draggedItem) {
            const { category: sourceCategory, itemIndex: sourceItemIndex } = draggedItem;

            // if target has no object (i.e., empty)
            // if(sourceItemIndex === 0 && targetItemIndex === 0){}

            if (sourceCategory === targetCategory && sourceItemIndex === targetItemIndex) {
                return; // Same item, no action needed
            }

            const updatedData = [...data];

            // Remove the dragged item from the source category
            const [draggedItemData] = updatedData
                .find((categoryData) => categoryData.category === sourceCategory)
                .items.splice(sourceItemIndex, 1);

            // Change the category property of the dragged item
            draggedItemData.category = targetCategory;

            // Insert the dragged item into the target category at the specified index
            updatedData
                .find((categoryData) => categoryData.category === targetCategory)
                .items.splice(targetItemIndex, 0, draggedItemData);
            setData(updatedData);
            setDraggedItem(null);
            setDataChanged(true);
        }
    };

    const handleUpdate = () => {
        fetch(`https://al-maghrib-server.vercel.app/team/updateDataAfterDND`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(data => {
                if (data.ok) {
                    alert("Rearrangement of members updated successfully.")

                } else {
                    alert("Sorry. rearrangement cannot be done. Try again")
                    setData(allMembers);
                }

            })
        setDataChanged(false);
    }


    return (
        <div style={{ display: 'flex' }}>
            <SidebarNew />
            <div className='container'>
                <h1 className='text-violet-900 text-6xl mb-5'>All Team Members</h1>
                {dataChanged &&
                    <div>
                        <p>Arrangement of team members has changed.</p>
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            onClick={handleUpdate}
                        >
                            Update
                        </button>
                    </div>
                }
                <div className="container">
                    {data &&
                        data.map((categoryData) => (
                            <div key={categoryData.category} className="mb-5">
                                <h3 className="text-violet-900 block text-sm font-medium mt-5">
                                    {categoryData.category}
                                </h3>
                                <table className="border-collapse border border-slate-400">
                                    <thead>
                                        <tr className="hover:bg-slate-400">
                                            <th className="border border-slate-300 p-3">Sl No</th>
                                            <th className="border border-slate-300 p-3">Name</th>
                                            <th className="border border-slate-300 p-3">Role/Designation</th>
                                            <th className="border border-slate-300 p-3">Image</th>
                                            <th className="border border-slate-300 p-3">Edit/Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody className="list-items">
                                        {categoryData.items.map((member, i) => (
                                            <tr
                                                key={member._id}
                                                className="hover:bg-slate-400"
                                                draggable
                                                onDragStart={() => handleDragStart(member.category, i)}
                                                onDragOver={(e) => handleDragOver(e, categoryData.category, i)}
                                            >
                                                <td className="border border-slate-300 p-3">{i + 1}</td>
                                                <td className="border border-slate-300 p-3">{member?.name}</td>
                                                <td className="border border-slate-300 p-3">{member?.role}</td>
                                                <td className="border border-slate-300 p-3">
                                                    <img
                                                        className="object-contain h-24"
                                                        src={member?.imageURL}
                                                        width={"50px"}
                                                        alt=""
                                                    />
                                                </td>
                                                <td className="border border-slate-300 p-3">
                                                    <button
                                                        className="btn btn-success"
                                                        onClick={() => handleEdit(member)}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModalScrollable"
                                                    >
                                                        Edit
                                                    </button>
                                                    {showModal && EditModal()}
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={() => handleDelete(member._id)}
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ))}
                </div>


            </div>


        </div>
    );
};

export default AllMembers;
