import React, { useEffect, useState } from 'react';
import SidebarNew from '../SidebarNew/SidebarNew';
import bannerPic from '../../Image/default-magazine.jpg'

const AllMagazines = () => {
    const [allMagazines, setAllMagazines] = useState([]);
    useEffect(() => {
        fetch(`https://al-maghrib-server.vercel.app/magazine/all`)
            .then(res => res.json())
            .then(data => {
                setAllMagazines(data.magazines)

            })
    }, []);

    const handleDelete = (magazineId) => {
        fetch(`https://al-maghrib-server.vercel.app/magazine/delete/${magazineId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(data => {
                console.log("ddd: ", data)
                if (data.ok) alert('One magazine has been deleted. Please refresh the page')
                else alert('XX:  Error to delete. Refresh the page and try again')
            })
    }
    return (
        <div style={{ display: 'flex' }}>
            <SidebarNew />
            <div className='container'>
                <h1 className='text-violet-900 text-6xl mb-5'>All Magazines</h1>

                <table className="border-collapse border border-slate-400">
                    <thead>
                        <tr className='hover:bg-slate-400'>
                            <th className="border border-slate-300 p-3">Sl No</th>
                            <th className="border border-slate-300 p-3">Date</th>
                            <th className="border border-slate-300 p-3">Headline </th>
                            <th className="border border-slate-300 p-3">Image </th>
                            <th className="border border-slate-300 p-3">Delete </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allMagazines.length !== 0 ? allMagazines.map((magazine, i) =>
                                <tr key={i} className='hover:bg-slate-400'>
                                    <td className="border border-slate-300 p-3">{i+1}</td>
                                    <td className="border border-slate-300 p-3">{magazine.date}</td>
                                    <td className="border border-slate-300 p-3">{magazine.headline}</td>
                                    <td className="border border-slate-300 p-3"><img className='object-contain h-24' src={magazine.imagesInfo !== undefined && magazine.imagesInfo[0]?.url || bannerPic} alt="" /></td>
                                    <td className="border border-slate-300 p-3">
                                        <button onClick={(e) => handleDelete(magazine._id)} className='btn btn-danger outline outline-1'>Delete</button> 
                                    </td>
                                </tr>
                            ) :
                                <tr>Data loading...</tr>
                        }


                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AllMagazines;