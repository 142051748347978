import React, { useRef, useState } from 'react';
import SidebarNew from '../SidebarNew/SidebarNew'
import { useForm } from 'react-hook-form';
import './CreateMagazine.css'
import axios from 'axios';

const CreateMagazine = () => {
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();



    // image
    const [selectedImages, setSelectedImages] = useState([]);
    const [showFileInput, setShowFileInput] = useState(true);
    const [imageURLs, setImageURLs] = useState([]);
    const [imgUploadProgress, setImgUploadProgress] = useState(0);
    const [isBannerDone, setIsBannerDone] = useState(false);

    const getImageDimensions = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const img = new Image();


                img.onload = () => {
                    resolve({
                        url: event.target.result,
                        width: img.width,
                        height: img.height,
                    });
                };

                img.onerror = () => {
                    reject(new Error('Failed to load image.'));
                };

                img.src = event.target.result;
            };

            reader.onerror = () => {
                reject(new Error('Failed to read file.'));
            };

            reader.readAsDataURL(file);
        });
    };

    const handleImageUpload = (event) => {
        const files = event.target.files;
        const imagesPromises = Array.from(files).map((file) => getImageDimensions(file));

        Promise.all(imagesPromises)
            .then((images) => {
                setSelectedImages((prevImages) => [...prevImages, ...images]);
                setShowFileInput(false);
            })
            .catch((error) => {
                console.error(error);
            });

        // host images
        const imageData = new FormData();
        imageData.set('key', '2dad6d7bf6cef1ec0ba10a9e39066744');
        imageData.append('image', event.target.files[0]);

        axios.post('https://api.imgbb.com/1/upload', imageData, {
            onUploadProgress: progressEvent => {
                const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                setImgUploadProgress(progress);
            }
        })
            .then(res => {
                res.data.data.display_url && setImageURLs([...imageURLs, res.data.data.display_url]);
                setImgUploadProgress(0);
                // add redundant image url: checking if the url is included or not, if included, add it
                if (imageURLs.includes(res.data.data.display_url)) {
                    imageURLs.push(res.data.data.display_url);
                    setImageURLs(imageURLs);
                }
            })
            .catch(err => {
                console.log(err);
            })
    };

    const handleAddImageButtonClick = () => {
        setShowFileInput(true);
    };

    // video
    const [videos, setVideos] = useState([{ name: 'video_1', value: '' }]);
    const handleInputChange = (index, value) => {
        const updatedVideos = [...videos];
        updatedVideos[index].value = value;
        setVideos(updatedVideos);
    };

    const handleAddAnother = () => {
        const newVideo = {
            name: `video_${videos.length + 1}`,
            value: '',
        };
        setVideos([...videos, newVideo]);
    };

    // read time
    const calculateReadTime = (text, imageCount) => {
        // Assuming an average reading speed of 225 words per minute
        const wordsPerMinute = 225;
        const words = text.split(/\s+/).length;
        const readTimeMinutes = words / wordsPerMinute;
        const imageReadingTime = imageCount * 0.5;

        // Round up to the nearest minute
        const readTime = Math.ceil(readTimeMinutes + imageReadingTime);
        return readTime;
    };

    // format date
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
        return formattedDate;
    };


    const onSubmit = data => {
        // loop to convert each url property of selectedImages to the corresponding content of imageURLs
        for (let i = 0; i < selectedImages.length; i++) {
            if (i < imageURLs.length) {
                selectedImages[i].url = imageURLs[i];
            }
        }


        const text = data.description1 + ' ' + data.description2;
        const readTime = calculateReadTime(text, selectedImages.length);

        const formattedDate = formatDate(data.date)

        const newMagazineData = {
            date: formattedDate,
            headline: data.headline,
            description1: data.description1,
            description2: data.description2,
            readTime: readTime,
            imagesInfo: selectedImages,
            videosInfo: videos
        }

        fetch(`https://al-maghrib-server.vercel.app/magazine/createMagazine`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newMagazineData)
        })
            .then(data => {
                if (data.ok) {
                    alert("Magazine created successfully.")
                } else { alert("Sorry. Magazine cannot created.") }

            })

        reset()
        setSelectedImages([])

    }
    return (
        <div style={{ display: 'flex' }}>
            <SidebarNew />
            <div className='container'>
                <h1 className='text-violet-900	text-6xl mb-5'>Create A Magazine</h1>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <label className="block">
                        <span className="block text-sm font-medium text-slate-700">Date</span>
                        <input type="date" name="date" {...register("date", { required: true })} className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        {errors.date && <span>This field is required</span>}
                    </label>

                    <label class="block">
                        <span className="mt-3 block text-sm font-medium text-slate-700">Headline</span>
                        <textarea type="text" name="headline" {...register("headline", { required: true })} rows="4" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        {/* errors will return when field validation fails  */}
                        {errors.headline && <span>This field is required</span>}
                    </label>

                    <label className="block">
                        <span className="mt-3 block text-sm font-medium text-slate-700">Description Text 1: </span>
                        <textarea type="text" name="description" {...register("description1", { required: true })} rows="4" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        {errors.description1 && <span>This field is required</span>}
                        <span className="mt-3 block text-sm font-medium text-slate-700">Description Text 2: </span>
                        <textarea type="text" name="description" {...register("description2")} rows="4" cols="50" className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />

                    </label>


                    <label className="block">
                        {
                            (imageURLs && imageURLs.length === 0) ? <span className="mt-3 block text-sm font-medium text-slate-700">Banner Image: </span>
                                : <span className="mt-3 block text-sm font-medium text-slate-700">Other Images: </span>
                        }


                        <div className="image-upload-container">
                            {imgUploadProgress > 0 && (
                                <div className="mt-4 w-64 bg-gray-200 h-4 rounded">
                                    <div
                                        className="h-full bg-blue-500 rounded"
                                        style={{ width: `${imgUploadProgress}%` }}
                                    >{imgUploadProgress}%</div>
                                </div>
                            )}
                            {showFileInput ? (
                                <label htmlFor="image-upload" className="add-image-button">
                                    Add an Image
                                    <input
                                        id="image-upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                    />
                                </label>
                            ) : (
                                <div className="image-preview-container  columns-3">
                                    {selectedImages.map((image, index) => (
                                        <div key={index} className="image-preview">
                                            <img src={image.url} alt="Uploaded" className="image" />
                                            <div className="image-info">
                                                <span>Width: {image.width}px</span>
                                                <span>Height: {image.height}px</span>
                                            </div>
                                        </div>
                                    ))}
                                    <button className="add-image-button" onClick={handleAddImageButtonClick}>
                                        Add an Image
                                    </button>
                                </div>
                            )}

                        </div>
                    </label>

                    <label className="block">
                        <span className="mt-3 block text-sm font-medium text-slate-700">Video: </span>
                        {videos.map((video, index) => (
                            <label key={video.name} className="block">
                                <span className="mt-3 block text-sm font-medium text-slate-700">
                                    Video {index + 1}:
                                </span>
                                <input
                                    type="text"
                                    className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400"
                                    name={video.name}
                                    value={video.value}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                />
                            </label>
                        ))}
                        <button type="button" className="mt-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                            onClick={handleAddAnother}>
                            + Add another
                        </button>

                    </label>

                    <br /><br />
                    <input type="submit" className='mt-3 mb-3 btn btn-success text-dark' />
                </form>
            </div>
        </div>
    );
};

export default CreateMagazine;