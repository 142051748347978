import React, { useState } from 'react';
import SidebarNew from '../SidebarNew/SidebarNew'
import { useForm } from "react-hook-form";
import axios from 'axios';
import { useEffect } from 'react';
import ImageGallery from './new/ImageGallery';

const FrontPage = () => {
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();

    const [carouselImages, setCarouselImages] = useState([]);
    const [imgUploadProgress, setImgUploadProgress] = useState(0);
    const [showFileInput, setShowFileInput] = useState(true);
    const [selectedImages, setSelectedImages] = useState([]);
    const [imageURLs, setImageURLs] = useState([]);

    useEffect(() => {
        fetch(`https://al-maghrib-server.vercel.app/front/carousel`)
            .then(res => res.json())
            .then(data => {
                setCarouselImages(data.frontPage[0].imagesInfo)

            })
    }, []);

    const handleAddImageButtonClick = () => {
        setShowFileInput(true);
    };

    const getImageDimensions = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const img = new Image();


                img.onload = () => {
                    resolve({
                        url: event.target.result,
                        width: img.width,
                        height: img.height,
                    });
                };

                img.onerror = () => {
                    reject(new Error('Failed to load image.'));
                };

                img.src = event.target.result;
            };

            reader.onerror = () => {
                reject(new Error('Failed to read file.'));
            };

            reader.readAsDataURL(file);
        });
    };

    const handleImageUpload = (event) => {
        const files = event.target.files;
        const imagesPromises = Array.from(files).map((file) => getImageDimensions(file));

        Promise.all(imagesPromises)
            .then((images) => {
                setSelectedImages((prevImages) => [...prevImages, ...images]);
                setShowFileInput(false);
                // console.log(selectedImages);
            })
            .catch((error) => {
                console.error(error);
            });

        // host images
        const imageData = new FormData();
        imageData.set('key', '2dad6d7bf6cef1ec0ba10a9e39066744');
        imageData.append('image', event.target.files[0]);

        axios.post('https://api.imgbb.com/1/upload', imageData, {
            onUploadProgress: progressEvent => {
                const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                setImgUploadProgress(progress);
                // console.log('Upload Progress: ' + progress + '%');
            }
        })
            .then(res => {
                // console.log(res.data.data.display_url);
                res.data.data.display_url && setImageURLs([...imageURLs, res.data.data.display_url]);
                setImgUploadProgress(0);
                // add redundant image url: checking if the url is included or not, if included, add it
                if (imageURLs.includes(res.data.data.display_url)) {
                    imageURLs.push(res.data.data.display_url);
                    setImageURLs(imageURLs);
                }
            })
            .catch(err => {
                console.log(err);
            })
    };

    const onSubmit = async (data) => {
        try {
            for (let i = 0; i < selectedImages.length; i++) {
                if (i < imageURLs.length) {
                    selectedImages[i].url = imageURLs[i];
                }
            }
            let newImagesInfo = [];
            if (carouselImages !== undefined) {
                newImagesInfo = [...carouselImages, ...selectedImages];
            } else newImagesInfo = [...selectedImages]

            const newCarouselImages = {
                imagesInfo: newImagesInfo
            };


            const id = '65b8c5823bced0cc93908532';

            const response = await fetch(`https://al-maghrib-server.vercel.app/front/carouselUpdate/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newCarouselImages)
            });

            const data = await response.json();

            if (data.success) {
                alert("Image(s) added successfully.");
            } else {
                alert("Sorry. Image(s) cannot be created.");
            }

            reset();
            setSelectedImages([]);
        } catch (error) {
            console.error("Error:", error);
            // Handle error as needed
        }
    };



    return (
        <div style={{ display: 'flex' }}>
            <SidebarNew />
            <div className='container'>
                <h1 className='text-violet-900	text-6xl	mb-5'>Home Page</h1>
                <br />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h5 className='text-violet-900	text-4xl mb-3'>Add an image</h5>
                    <ol className='list-decimal ml-8 text-sm'>
                        <li>Click on the button below</li>
                        <li>Select image</li>
                        <li>Wait for 100% if progress is shown</li>
                        <li>Repeat step 1-3 to add another image (if needed)</li>
                        <li>Click submit button below</li>
                        <li>Reload the page</li>
                    </ol>
                    <div>
                        <div className="image-upload-container">
                            {imgUploadProgress > 0 && (
                                <div className="mt-4 w-64 bg-gray-200 h-4 rounded">
                                    <div
                                        className="h-full bg-blue-500 rounded"
                                        style={{ width: `${imgUploadProgress}%` }}
                                    >{imgUploadProgress}%</div>
                                </div>
                            )}
                            {showFileInput ? (
                                <label htmlFor="image-upload" className="add-image-button">
                                    + Add an Image
                                    <input
                                        id="image-upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                    />
                                </label>
                            ) : (
                                <div className="image-preview-container  columns-3">
                                    {selectedImages.map((image, index) => (
                                        <div key={index} className="image-preview">
                                            <img src={image.url} alt="Uploaded" className="image" />
                                            <div className="image-info">
                                                <span>Width: {image.width}px</span>
                                                <span>Height: {image.height}px</span>
                                            </div>
                                        </div>
                                    ))}
                                    <button className="add-image-button" onClick={handleAddImageButtonClick}>
                                        Add an Image
                                    </button>
                                </div>
                            )}

                        </div>
                    </div>



                    <br />
                    <input type="submit" className='mt-3 btn btn-success text-dark mt-5 mb-5' />
                </form>
                <h5 className='text-violet-900	text-4xl mb-3'>Rearrange and Delete</h5>
                <ul className='list-disc ml-8 text-sm'>
                    <li className="mb-2">
                        <span className="font-bold">To delete</span>
                        <ol className='list-decimal ml-4'>
                            <li>Check the images you want to delete</li>
                            <li>Click <span className='text-red-500'>Delete file(s)</span> button</li>
                        </ol>
                    </li>
                    <li className="mb-2">
                        <span className="font-bold">To rearrange</span>
                        <ol className='list-decimal ml-4'>
                            <li>Drag and drop the images by mouse/hand</li>
                            <li>If you modify the image positions, click <span className='text-blue-400'>Re-arrange</span> button</li>
                            <li>No need to click the button if you don't think to modify the positions of images.</li>
                        </ol>
                    </li>
                </ul>
                <ImageGallery carouselImages={carouselImages} />
            </div>
        </div>
    );
};

export default FrontPage;